
// this is ridiculous, but it seems impossible to get shadows working without using an existing material.
// threejs should not tie the shadow framework into the material framework.

export function build(material, uniforms) {

    material.onBeforeCompile = (shader) => {

        shader.uniforms.normalMap = uniforms.normalMap;
        shader.uniforms.splatMap = uniforms.splatMap;
        shader.uniforms.diffuseMaps = uniforms.diffuseMaps;
        shader.uniforms.normalMaps = uniforms.normalMaps;
        shader.uniforms.roughnessMaps = uniforms.roughnessMaps;
        shader.uniforms.aoMaps = uniforms.aoMaps;
        shader.uniforms.sunPosition = uniforms.sunPosition;
        shader.uniforms.viewDirection = uniforms.viewDirection;

        // outside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <common>`,
          `
          #include <common>

          // these need to be added if normalmap texture is not provided
          attribute vec3 tangent; 
          attribute vec3 bitangent;
          attribute vec2 uv2;

          //varying vec3 vtWorldPosition;
          varying vec3 vWorldNormal;
          varying vec3 vWorldPosition;
          varying vec2 vUv;
          varying vec2 vUv2;
          varying mat3 vTBN;

          varying float vFresnel;
          varying float vFresnel2;
          varying float vFresnel4;
          varying float vTerrainHeight;

          uniform vec3 viewDirection;
          uniform vec3 sunPosition;

          varying vec3 forwardVector;
          varying vec3 lightDirection;

          `
        );

        // inside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <project_vertex>`,
          `
          #include <project_vertex>

          vUv = uv; // covers segment, typically used for repeating textures.
          vUv2 = uv2; // covers full grid, splatmap etc covers segment.
          vTerrainHeight = 40.0;

          vec3 N = normalize(vec3(modelViewMatrix * vec4(normal, 0.0)));
          vec3 T = normalize(vec3(modelViewMatrix * vec4(tangent, 0.0)));
          vec3 B = normalize(vec3(modelViewMatrix * vec4(bitangent, 0.0)));

          vTBN = mat3(T, B, N); // Now it's in world space

          `
        );

        // set positions
        shader.vertexShader = shader.vertexShader.replace(
          `#include <worldpos_vertex>`,
          `
          #include <worldpos_vertex>
          vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;

          forwardVector = normalize(viewDirection);
          //lightDirection = normalize(-sunPosition);
          lightDirection = normalize(sunPosition - vWorldPosition);

          vFresnel = 1.0 - clamp(dot(forwardVector, vWorldNormal), 0.0, 1.0);
          vFresnel2 = pow(vFresnel, 2.0);
          vFresnel4 = pow(vFresnel, 4.0);
          `
        );

        shader.vertexShader = shader.vertexShader.replace(
          `#include <defaultnormal_vertex>`,
          `
          #include <defaultnormal_vertex>
          vWorldNormal = normalize(vec3(modelMatrix * vec4(normal, 0.0)));
          `
        );

        shader.onBeforeCompile = (shader) => {
          shader.fragmentShader = shader.fragmentShader.replace(
            'specular = vec3(1.0);',
            'specular = vec3(0.0);'
          );
        };

        // outside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <common>`,
          `
          #include <common>

          uniform mat3 normalMatrix;
          uniform mat4 modelMatrix;
          uniform sampler2D normalMap;
          uniform sampler2D splatMap;
          //uniform vec2 normalScale;

          varying vec2 vUv;
          varying vec2 vUv2;
          varying vec3 vWorldPosition;
          varying vec3 vWorldNormal;
          varying mat3 vTBN;

          varying float vTerrainHeight;
          varying float vFresnel;
          varying float vFresnel2;
          varying float vFresnel4;

          uniform vec3 sunPosition;
          uniform vec3 viewDirection;
          uniform sampler2DArray diffuseMaps;
          uniform sampler2DArray normalMaps;
          uniform sampler2DArray roughnessMaps;
          uniform sampler2DArray aoMaps;

          uniform vec3 forwardVector;
          uniform vec3 lightDirection;

          struct DetileData {
              vec2 offa;
              vec2 offb;
              vec2 dx;
              vec2 dy;
              float blendFactor;
          };

          float sum( vec3 v ) { return v.x+v.y+v.z; }

          float hash(vec2 n) {
              return fract(sin(dot(n.x,n.y)));
              //return fract(dot(n.x,n.y));
          }

          float valueNoise(vec2 uv) {
              vec2 p0 = floor(uv);
              vec2 p1 = p0 + vec2(1.0, 0.0);
              vec2 p2 = p0 + vec2(0.0, 1.0);
              vec2 p3 = p0 + vec2(1.0, 1.0);

              float v0 = fract(sin(p0.x + p0.y * 64.0) * 43758.5453);
              float v1 = fract(sin(p1.x + p1.y * 64.0) * 43758.5453);
              float v2 = fract(sin(p2.x + p2.y * 64.0) * 43758.5453);
              float v3 = fract(sin(p3.x + p3.y * 64.0) * 43758.5453);
            //  float v0 = fract(sin(p0.x + p0.y * 57.0) * 43758.5453);
            //  float v1 = fract(sin(p1.x + p1.y * 57.0) * 43758.5453);
            //  float v2 = fract(sin(p2.x + p2.y * 57.0) * 43758.5453);
            //  float v3 = fract(sin(p3.x + p3.y * 57.0) * 43758.5453);

              float tx = uv.x - p0.x;
              float ty = uv.y - p0.y;
              float v0_v1 = mix(v0, v1, tx);
              float v2_v3 = mix(v2, v3, tx);
              return mix(v0_v1, v2_v3, ty);
          }

          vec4 hash4( vec2 p ) { return fract(sin(vec4( 1.0+dot(p,vec2(37.0,17.0)),
                  2.0+dot(p,vec2(11.0,47.0)),
                  3.0+dot(p,vec2(41.0,29.0)),
                  4.0+dot(p,vec2(23.0,31.0))))*103.0); }

          float getSlopeFactor(vec3 normal) {

              return 1.0 - abs(dot(normal, vec3(0.0, 1.0, 0.0)));

          }

          // function to randomize textures to prevent tiling effect, based off
          // https://iquilezles.org/articles/texturerepetition/
          //
          // This only works well for repeating textures
          vec4 textureNoTileArray(sampler2DArray texArr, in vec2 uv, int layer) {
              // Sample variation pattern (cache-friendly lookup)

              float k = valueNoise(uv);

              vec2 duvdx = dFdx( uv );
              vec2 duvdy = dFdy( uv );
              
              float l = k*8.0;
              float f = fract(l);
              
              float ia = floor(l);
              float ib = ia + 1.0;

              vec2 offa = sin(vec2(7.0,7.0)*ia); // can replace with any other hash
              vec2 offb = sin(vec2(7.0,7.0)*ib); // can replace with any other hash

              vec4 cola = textureGrad( texArr, vec3(uv + offa, layer), duvdx, duvdy );
              vec4 colb = textureGrad( texArr, vec3(uv + offb, layer), duvdx, duvdy );

              return mix( cola, colb, smoothstep(0.2,0.8,f-0.1 * sum(cola.xyz-colb.xyz)) );

              /*
              // Compute derivatives for mipmapping
              vec2 dx = dFdx(uv), dy = dFdy(uv);

              // texture hash
              //vec2 perturb = murmurHash22(uv * 10.0) * 0.05;

              // Sample the two closest virtual patterns

              vec4 cola = textureGrad(texArr, vec3(uv + offa, layer), dx, dy);
              vec4 colb = textureGrad(texArr, vec3(uv + offb, layer), dx, dy);
              //vec4 cola = texture(texArr, vec3(uv + offa, layer));
              //vec4 colb = texture(texArr, vec3(uv + offb, layer));

              // Interpolate between the two virtual patterns
              return mix(cola, colb, smoothstep(0.2, 0.8, f - 0.1 * dot(cola.rgb - colb.rgb, vec3(1.0))));
              */
          }

          // optimimzation for detile effect.
          DetileData computeDetilingOffset(vec2 uv) {

              float k = valueNoise(uv);
              float l = k * 8.0;
              float f = fract(l);

              float ia = floor(l);
              float ib = ia + 1.0;

              vec2 dx = dFdx(uv);
              vec2 dy = dFdy(uv);

              vec2 offa = sin(vec2(3.0, 7.0) * ia);
              vec2 offb = sin(vec2(3.0, 7.0) * ib);

              float blendFactor = smoothstep(0.2, 0.8, f-0.1);

              return DetileData(uv + offa, uv + offb, dx, dy, blendFactor);

          }

          vec4 detileTexture(sampler2DArray texArr, DetileData data, int layer) {

              vec4 cola = textureGrad(texArr, vec3(data.offa, layer), data.dx, data.dy);
              vec4 colb = textureGrad(texArr, vec3(data.offb, layer), data.dx, data.dy);

              // Blend the two samples using the precomputed blend factor
              return mix(cola, colb, data.blendFactor);

          }

          // testing
          vec4 textureNoTile(sampler2DArray texArr, in vec2 uv, int layer) {
              
              vec2 p = floor( uv );
              vec2 f = fract( uv );

              // derivatives (for correct mipmapping)
              vec2 ddx = dFdx( uv );
              vec2 ddy = dFdy( uv );

              // voronoi contribution
              vec4 va = vec4( 0.0 );
              float wt = 0.0;
              for( int j=-1; j<=1; j++ )
              for( int i=-1; i<=1; i++ )
              {
                  vec2 g = vec2( float(i), float(j) );
                  vec4 o = hash4( p + g );
                  vec2 r = g - f + o.xy;
                  float d = dot(r,r);
                  float w = exp(-5.0*d );
                  vec2 d_uv_dx = dFdx(uv); // Derivative in x direction
                  vec2 d_uv_dy = dFdy(uv); // Derivative in y direction
                  vec4 c = textureGrad(texArr, vec3(uv, layer), d_uv_dx, d_uv_dy);
                  va += w*c;
                  wt += w;
              }

              // normalization
              return va/wt;
          }

          float getBlendFactor(float heightA, float heightB, float depth) {
              float threshold = max(heightA, heightB) - depth;
              return clamp((heightA - threshold) / (heightA - heightB + 0.0001), 0.0, 1.0);
          }

          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <map_fragment>`,
          `
          #include <map_fragment>

          //#include <normal_pars_fragment>
          //#include <normalmap_pars_fragment>

          vec4 splatTex = texture(splatMap, vUv2);
          vec4 normalTex = texture(normalMap, vUv2);

          float shadowPower = 1.0;
          vec3 shadowColor = vec3(0, 0, 0);
          vec3 lightGrass = vec3(0.7, 0.6, 0.05);
          vec3 darkGrass = vec3(0.6, 0.7, 0.1);
          vec3 rockColor = vec3(0.8, 0.8, 0.88);

          DetileData detileData = computeDetilingOffset(vUv * 4.0);
          DetileData detileDataH = computeDetilingOffset(vUv * 32.0);
          DetileData detileDataL = computeDetilingOffset(vUv2 * 10.0);

          // extract diffuse maps
          vec4 dirtDiffuse = detileTexture(diffuseMaps, detileDataH, 0);
          vec4 grassDiffuse = detileTexture(diffuseMaps, detileData, 1);
          vec4 gravelDiffuse = detileTexture(diffuseMaps, detileDataH, 2);
          vec4 rockDiffuse = detileTexture(diffuseMaps, detileData, 3);
          vec4 rockDiffuseFar = detileTexture(diffuseMaps, detileDataL, 3);

          // extract normal maps
          vec4 dirtNormal = detileTexture(normalMaps, detileDataH, 0);
          vec4 grassNormal = detileTexture(normalMaps, detileData, 1);
          vec4 gravelNormal = detileTexture(normalMaps, detileDataH, 2);
          vec4 rockNormal = detileTexture(normalMaps, detileData, 3);
          vec4 rockNormalFar = detileTexture(normalMaps, detileDataL, 3);

          // extract roughness maps
          vec4 dirtRoughness = detileTexture(roughnessMaps, detileDataH, 0);
          vec4 grassRoughness = detileTexture(roughnessMaps, detileData, 1);
          vec4 gravelRoughness = detileTexture(roughnessMaps, detileDataH, 2);
          vec4 rockRoughness = detileTexture(roughnessMaps, detileData, 3);
          vec4 rockRoughnessFar = detileTexture(roughnessMaps, detileDataL, 3);

          // extract ao maps
          // vec4 grassAo = textureNoTileArray(aoMaps, vUv, 0);
          vec4 dirtAo = texture(aoMaps, vec3(vUv, 3));
          vec4 grassAo = texture(aoMaps, vec3(vUv, 0));
          vec4 gravelAo = texture(aoMaps, vec3(vUv, 1));
          vec4 rockAo = texture(aoMaps, vec3(vUv, 4));
          vec4 rockAoFar = texture(aoMaps, vec3(vUv, 4));

          // get pixel height for blending
          float grassStr = grassDiffuse.r + grassDiffuse.g + grassDiffuse.b;
          float dirtStr = dirtDiffuse.r + dirtDiffuse.g + dirtDiffuse.b;
          float rockStr = rockDiffuse.r + rockDiffuse.g + rockDiffuse.b;

          float maxStr = max(grassStr, max(dirtStr, rockStr));
          float threshold = max(maxStr - 0.1, 0.0);

          // material masks for blending
          float mGrass = clamp((threshold-grassStr) / threshold, 0.0, 1.0);
          float mDirt = clamp((threshold-dirtStr) / threshold, 0.0, 1.0);
          float mRock = clamp((threshold-rockStr) / threshold, 0.0, 1.0);

          // calculate slope
          float slope = getSlopeFactor(normalize(vWorldNormal));

          // distance from camera
          float distance = length(vWorldPosition.xyz - cameraPosition);

          float x = smoothstep(0.5, 0.3, distance);

          // factors for blending textures
          float heightFactor = vWorldPosition.y / vTerrainHeight;
          float dirtFactor = smoothstep(0.5, 0.3, (distance - slope  // show dirt when camera is close but not on slopes.
                                               + distance * mDirt)); // and blend with the color strength
          float grassFactor = smoothstep(0.0, 0.15, slope); // grass on flat surfaces
          float rockFactor = smoothstep(0.15, 0.2, slope * (1.0 + mGrass)); // grass blends over rock

          dirtFactor -= rockFactor;
        // grassFactor -= rockFactor;

          // Normal maps converted to -1 to 1 range
          vec3 grassNormalVec = normalize(grassNormal.rgb * 2.0 - 1.0);
          vec3 dirtNormalVec = normalize(dirtNormal.rgb * 2.0 - 1.0);
          vec3 rockNormalVec = normalize(rockNormal.rgb * 2.0 - 1.0);
          vec3 rockFarNormalVec = normalize(rockNormalFar.rgb * 2.0 - 1.0);

          float grassFade = smoothstep(5.0, 50.0, distance);
          float rockFade = smoothstep(10.0, 50.0, distance);
          float rockFadeFar = smoothstep(10.0, 200.0, distance);

          // Grass fades to green at a distance (on top of the grass-dirt blend)
          grassDiffuse = vec4(mix(grassDiffuse.rgb, lightGrass, grassFade * grassFactor), grassDiffuse.a);
          //rockDiffuse = vec4(mix(rockDiffuse.rgb, vec3(0.8, 0.8, 0.9), rockFade * rockFactor), rockDiffuse.a);
          //rockDiffuse = vec4(mix(rockDiffuse.rgb, rockDiffuseFar.rgb, rockFade * rockFactor), rockDiffuse.a);

          //vec3 baseColor = rockDiffuse.rgb * rockDiffuseFar.rgb;

          vec3 baseColor = mix(rockDiffuse.rgb * rockDiffuseFar.rgb, // blend near and far textures
                     rockDiffuse.rgb + rockDiffuseFar.rgb * 0.5,
                     rockFade * rockFactor);
          rockDiffuse = vec4(mix(baseColor, rockDiffuseFar.rgb, rockFade * rockFactor), rockDiffuse.a);

          // same for normals, we fade back towards texture normals.. we whould really use vertex normals
          //grassNormalVec = normalize(mix(grassNormalVec, normalTex.rgb, grassFade));
          //rockNormalVec = normalize(mix(rockNormalVec, normalTex.rgb, rockFade));
          grassNormalVec = normalize(mix(grassNormalVec, vWorldNormal, grassFade * grassFactor));
          //rockNormalVec = normalize(mix(rockNormalVec, vWorldNormal, rockFade * rockFactor));
          rockNormalVec = normalize(mix(rockFarNormalVec, vWorldNormal, rockFade * rockFactor));

          // Grass blending with dirt close to the camera
          vec4 grassDirtDiffuse = mix(grassDiffuse, dirtDiffuse, dirtFactor);
          vec3 grassDirtNormal = normalize(mix(grassNormalVec, dirtNormalVec, smoothstep(0.0, 0.5, dirtFactor)));

          vec4 materialDiffuse = mix(
              grassDirtDiffuse,
              rockDiffuse,
              rockFactor
          );

          vec3 materialNormal = normalize(
              mix(
                grassDirtNormal,
                rockNormalVec,
                rockFactor
              )
          );

          // diffuse
          vec4 diffuse = materialDiffuse;

          vec4 roughness4 = mix(grassRoughness, dirtRoughness, dirtFactor);
          roughness4 = mix(roughness4, rockRoughness, rockFactor);
          
          // ao
          vec4 ao = mix(grassAo, dirtAo, dirtFactor);
          ao = mix(ao, rockAo, rockFactor);

          diffuseColor = diffuse;

          // #include <dithering_fragment>
          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <roughnessmap_fragment>`,
          `
          float roughnessFactor = roughness4.g * roughness;

          #ifdef USE_ROUGHNESSMAP

            vec4 texelRoughness = texture( roughnessMap, vRoughnessMapUv );

            // reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
            // roughnessFactor *= texelRoughness.g;
            roughnessFactor = mix(roughnessFactor, texelRoughness.g, 0.5);

          #endif
          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_begin>`,
          `

          // vec3 forwardVector = normalize(viewDirection);
          // vec3 lightDirection = normalize(-sunPosition);

          float materialFactor = smoothstep(10.0, 0.0, distance);

          // vec3 tangentNormal = normalTex.rgb;
          vec3 tangentNormal = normalize(normalTex.rgb * 2.0 - 1.0);
          vec3 tangentNormalWorld = normalize(vTBN * tangentNormal);

          // Use tangent/normal texture map
          float matVec = abs(dot(tangentNormalWorld, materialNormal));
          normal = normalize(mix(tangentNormalWorld, materialNormal, materialFactor * matVec));

          // Use precalculated normals only (also good quality) TODO: make normalTex optional
          // float matVec = abs(dot(normal, materialNormal));
          // normal = normalize(mix(normal, materialNormal, materialFactor * matVec));

          // boost final normal
          normal = normalize(normal * 2.0);

          #include <lights_fragment_begin>

          // calculate emissive light for stylized effect based on roughness map
          float emissiveFactor = 1.0 - roughnessFactor;

          // Fresnel for iridescence stylized effect
          float grassFresnel = vFresnel;
          float rockFresnel = vFresnel2;

          // Rock and grass emissive 
          vec3 rockEmissive = 0.5 * rockColor;
          vec3 grassEmissive = 0.5 * lightGrass;

          // Iridescence based on Fresnel
          vec3 rockIridescence = 0.4 * rockColor * rockFresnel * rockFactor;
          vec3 grassIridescence = 0.3 * lightGrass * grassFresnel * grassFactor;

          float angle = dot(forwardVector, lightDirection);

          // Combine iridescence and emissive effects
          vec3 iridescence = mix(grassFresnel * grassIridescence, rockFresnel * rockIridescence, 0.5);
          float fresnelEdge = pow(1.0 - abs(dot(forwardVector, vWorldNormal)), 1.0);
          // hee
          //vec3 emissive = emissiveFactor * (rockEmissive + grassEmissive) + iridescence * fresnelEdge;

          `
        );

        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_end>`,
          `
          // Add the iridescence effect to the final lighting
          //reflectedLight.directDiffuse = clamp(reflectedLight.directDiffuse + reflectedLight.directDiffuse * emissive, 0.0, 1.0);
          reflectedLight.directDiffuse = clamp(reflectedLight.directDiffuse, 0.0, 1.0);

          //vec3 lightColor = normalize(lightDirection) * 0.5 + 0.5;
          //gl_FragColor = vec4(lightColor, 1.0);

          #include <lights_fragment_end>
          `
        );

    return material;
}
/*
        shader.vertexShader = shader.vertexShader.replace(
            `#include <uv_pars_vertex>`,
            `varying vec2 vUv;
             out vec2 vUvL;
             out vec3 vNormal;
             uniform float uTime;`
        );
*/

};

<!--
 The Entity Component creates regular Threejs Objects with userData metadata that makes it compliant in usagami engine
 For example the entity can emit a request to add rigid body physics into the node graph.
-->

<template>
  <div ref="rootNode" />
</template>

<script>
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { emits, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import * as THREE from 'three';

export default {
  name: 'Entity',
  "components": {
  },
  "props": {
  },
  "emits": [
      "add-entity",
      "create-rigid-body"
  ],
  setup (props, {emit}) {
    const scene = inject('scene'),
    camera = inject('camera'),
    mouseInfo = inject('mouseInfo'),
    utils = inject('utils'),
    rootNode = ref(),
    createBox = (geom, position, rotation, inertia) => {

      const geometry = new THREE.BoxGeometry(geom.x, geom.y, geom.z),
        material = new THREE.MeshLambertMaterial({ color: 0xfcae1e }),
        mesh = new THREE.Mesh(geometry, material),
        node = new THREE.Object3D();

      mesh.castShadow = true;
      mesh.receiveShadow = true;

      node.position.set(position.x,position.y,position.z);
      node.add(mesh);

      const mass = 1,

       shape = {
        "type": "box",
        "box": {
          "size": [geom.x,geom.y,geom.z],
        }
      }

      emit("add-entity", {
        'name': 'box',
        node
      });

      emit("create-rigid-body", {
        node,
        shape,
        mass,
        inertia,
        kinematic: false
      });

    },
    createSphere = (geom, position, rotation, inertia) => {

      const sphereGeometry = new THREE.SphereGeometry(geom.x, geom.y, geom.z),
        material = new THREE.MeshLambertMaterial({ color: 0xfcae1e }),
        mesh = new THREE.Mesh(sphereGeometry, material),
        node = new THREE.Object3D();

      mesh.castShadow = true;
      mesh.receiveShadow = true;

      node.position.set(position.x,position.y,position.z);
      node.add(mesh);

      const pointLight = new THREE.PointLight(0xffffff, 10, 0, 2);
      //pointLight.castShadow = true;
      //pointLight.shadow.mapSize.width = 1024;
      //pointLight.shadow.mapSize.height = 1024;
      // pointLight.position.set(new THREE.Vector3(0,0,2));
      node.add(pointLight)

      emit("add-entity", {
        'name': 'sphere',
        node
      });

      const shape = {
        "type": "sphere",
        "sphere": {
          "radius": geom.x,
        }
      },

       mass = 1;

      emit("create-rigid-body", {
        node,
        shape,
        mass,
        inertia,
        kinematic: false
      });
    
    },
    loadModel = (n,p) => {

      // Set up scene

      const geometry = new THREE.BufferGeometry(),

       textureLoader = new THREE.TextureLoader(),

       loader = new GLTFLoader();

      loader.load(n.modelFile, (gltf) => {

          console.log('gltf');
          console.log(gltf);
          console.log('gltf.scene');
          console.log(gltf.scene);

          const physics = {};

          physics.shapes = gltf.userData?.gltfExtensions?.KHR_collision_shapes?.shapes
          physics.joints = gltf.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.physicsJoints
          physics.filters = gltf.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.collisionFilters
          physics.materials = gltf.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.physicsMaterials

          const animationMixer = new THREE.AnimationMixer(gltf.scene);

          gltf.scene.position.copy(new THREE.Vector3(p,0.01,5));

          parseNode(gltf.scene);

          gltf.scene.traverse((child)=> {

              parseNode(child, physics);

          });

          var animations = {};
          var inverseKenetics = {};
          for (var i=0; i < gltf.animations.length; i++) {

              animations[gltf.animations[i].name] = gltf.animations[i];

          }

          // generate dynamic animations if missing
          if (animations['walk'] && animations['rightPistol']) {

              if (!animations['pistolWalk']) {

                  animations['pistolWalk'] = generateAnimation(gltf.scene, animations['walk'], animations['rightPistol'], 'mixamorigRightArm', 0.4);

              }

          }

          // TODO create a file format that wraps the gltf and allows additional info.

          emit("add-entity", {
            name: 'cyberGirl',
            node: gltf.scene,
            animations,
            animationMixer,
            actions: {},
            state: {}
          });

      });

    },
    parseNode = (node, physics, texture, normalMap) => {

      if (node.type == "Mesh") {

  /*
   *node.castShadow = true; //default is false
   *node.receiveShadow = false; //default
   *
   * // set initial rotation.
   *node.rotation.x = THREE.MathUtils.degToRad(180);
   *
   *const uvAttribute = node.geometry.getAttribute('uv');
   *
   *for (let i = 0; i < uvAttribute.count; i++) {
   *
   *    uvAttribute.setX(i, 1 - uvAttribute.getX(i));
   *
   *}
   *
   *uvAttribute.needsUpdate = true;
   */

/*
 *
 *const material = new THREE.MeshStandardMaterial({
 *    metalness: 0.8,
 *    roughness: 0.1,
 *    wireframe: false,
 *    transparent: true,
 *    opacity: 1,
 *    depthWrite: false,
 *    bumpMap: texture,
 *    map: texture,
 *    normalMap,
 *    bumpScale: 0.5
 *});
 *node.material = material;
 *node.castShadow = true;
 *node.receiveShadow = false;
 *
 * // runtime vars
 *let initialRotation = 0;
 *let time = 0;
 *
 * // simple effect
 *const effect = function () {
 *
 *    material.opacity = 0.5;
 *    material.color = 0xe385f7;
 *
 *    setTimeout(()=> {
 *
 *        material.opacity = 1;
 *        material.color = "";
 *
 *    }, 10);
 *
 *}
 */

      }

      if (node.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.joint) {

          console.log("it's a joint");
          console.log(node.userData.gltfExtensions.KHR_physics_rigid_bodies.joint)

      }

      if (node.userData?.gltfExtensions?.KHR_physics_rigid_bodies?.collider) {

          console.log("it's a collider")
          console.log(node.userData.gltfExtensions.KHR_physics_rigid_bodies.collider)
          console.log(node)
          const mass = node.userData.gltfExtensions.KHR_physics_rigid_bodies.motion.mass | 1,
           shape_id = node.userData.gltfExtensions.KHR_physics_rigid_bodies.collider.shape | 0,
           shape = physics.shapes[shape_id];

          emit("create-rigid-body", {
            node,
            shape,
            mass,
            "inertia": null,
            "kinematic": true
          });

      }

    },
    generateAnimation = (node, base, override, bones, blend) => {

        const baseClip = base;
        const overrideClip = override;

        let filter = [];

        if (Array.isArray(bones)) {

            filter = bones;

        } else if (bones) {

            filter.push(bones);

        } 

        for (var i in filter) {

            let bone = filter[i].replace('.','');
            const obj = utils.findObjectByName(node, bone);
            obj.traverse((child) => {

                if (child.isBone) {

                    filter.push(child.name);

                }

            });

        }

        console.log(filter)

        let x = new Date();

        for (var i=0; i < overrideClip.tracks.length; i++) {

            if (filter.includes(overrideClip.tracks[i].name.split('.')[0])) {

                for (var j=0; j < baseClip.tracks.length; j++) {

                    if (overrideClip.tracks[i].name == baseClip.tracks[j].name) {

                        if (bones.includes(baseClip.tracks[j].name)) {
                            delete baseClip.tracks[j];
                            continue;
                        }

                        if (blend) {

                            baseClip.tracks[j].values = utils.blendArrays(baseClip.tracks[j].values, overrideClip.tracks[i].values, blend);
                            continue;

                        } else {

                            baseClip.tracks[j] = overrideClip.tracks[i];
                            continue;

                        }

                    }

                }

            }

        }

        return baseClip;

    },
    reap = () => {};

    onMounted(() => {

    });

    onBeforeUnmount(() => {

    });

    return {
      scene,
      rootNode,
      parseNode,
      createBox,
      createSphere,
      loadModel,
      generateAnimation,
      reap,
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

</style>
